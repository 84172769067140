import { useEffect } from "react";

export function Loader({ loading }: { loading: boolean }) {
  useEffect(() => {
    const splashScreen = document.getElementById("splash-screen");

    if (splashScreen && loading) {
      splashScreen.classList.remove("hidden");

      return () => {
        splashScreen.classList.add("hidden");
      };
    }

    let timeout: number;
    if (splashScreen && !loading) {
      timeout = window.setTimeout(() => {
        splashScreen.classList.add("hidden");
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [loading]);
  return <></>;
}
