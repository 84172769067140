import { classNames } from "../utils";

export function Waiting({ className }: { className?: string }) {
  return (
    <div className="mt-6 flex justify-center py-10 md:py-16 xl:py-20">
      <div
        className={classNames(
          "pointer-events-none relative inline-block w-28 text-primary-700 opacity-50",
          className ? className : ""
        )}
        aria-hidden="true"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 60 60"
          enableBackground="new 0 0 60 60"
          className="h-auto w-full"
        >
          <path
            fill="currentColor"
            d="M35.7,15.9H24.9l-7.7,24h9.4l1-3.6h4.2l0.9,3.6h10.6L35.7,15.9z M32.7,35.2h-5.8l-1,3.6h-7.2l7-21.9H35
		L42,38.8h-8.4L32.7,35.2z"
          />
          <path
            fill="currentColor"
            d="M28.6,31.7h3.4L30.5,24L28.6,31.7z M29.8,30.8l0.6-2.5l0.5,2.5H29.8z"
          />
        </svg>

        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 60 60"
          enableBackground="new 0 0 60 60"
          className="absolute left-0 top-0 h-auto w-full animate-spin-slow duration-0"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" width="60" height="60" />
            </defs>
            <clipPath id="SVGID_00000024683625024831304530000008689717097200276658_">
              <use href="#SVGID_1_" xlinkHref="#SVGID_1_" overflow="visible" />
            </clipPath>
            <path
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              d="M4.3,24.8
		c-0.1,0.4-0.5,0.7-0.8,0.6c-0.4-0.1-0.6-0.5-0.6-0.8C3,24.1,3.3,23.9,3.7,24C4.1,24,4.4,24.4,4.3,24.8"
            />
            <path
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              d="M8.5,16.4l-1-0.1l-0.8,1.5
		l0.6,0.8l-0.4,0.8l-2.7-3.5l0.4-0.7L9,15.5L8.5,16.4z M5.3,16l0.9,1.1l0.5-1L5.3,16z"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="10,14.3 6.9,11.6 
		7.4,10.9 9.9,13 11.2,11.5 11.8,12 	"
            />
            <path
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              d="M13.3,9.1l-0.5,0.4l0.8,0.9
		L12.8,11l-2.6-3.1l1.3-1.1c0.7-0.6,1.6-0.6,2.1,0.1l0,0C14.2,7.7,14,8.5,13.3,9.1 M12.9,7.5c-0.3-0.3-0.6-0.3-0.9,0L11.4,8l0.8,1
		l0.5-0.5C13.1,8.2,13.1,7.8,12.9,7.5L12.9,7.5z"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="19,6.8 18.2,5.3 
		16.8,6.1 17.6,7.6 16.8,8 14.9,4.4 15.7,4 16.4,5.4 17.9,4.6 17.1,3.2 17.9,2.8 19.8,6.4 	"
            />
            <path
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              d="M24.6,4.8L24,4l-1.7,0.5
		l-0.1,1l-0.9,0.2l0.6-4.4l0.8-0.2l2.8,3.5L24.6,4.8z M22.6,2.2l-0.2,1.4l1-0.3L22.6,2.2z"
            />
            <path
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              d="M30.3,3.2
		c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7C31,2.9,30.7,3.2,30.3,3.2"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="38.1,2.1 37.2,5.3 
		36.4,5 37.2,1.9 36,1.5 36.2,0.8 39.5,1.6 39.3,2.4 	"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="42.2,7.3 42.9,5.9 
		41.5,5.1 40.7,6.6 40,6.2 41.8,2.5 42.6,2.9 41.9,4.4 43.3,5.1 44,3.7 44.8,4.1 43,7.7 	"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="44.8,9 47.4,5.8 
		49.8,7.7 49.3,8.4 47.6,7 47.1,7.6 48.5,8.8 48,9.5 46.6,8.2 46,8.9 47.7,10.3 47.2,10.9 	"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="53.8,13.8 
		51.1,15.6 50.6,14.9 53.3,13 52.6,12 53.3,11.5 55.2,14.3 54.5,14.8 	"
            />
            <path
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              d="M53.2,19.6
		c-0.5-1.2,0-2.4,1.1-2.8l0,0c1.1-0.5,2.3,0,2.8,1.2c0.5,1.2,0,2.4-1.1,2.8l0,0C54.9,21.2,53.7,20.7,53.2,19.6 M55.6,19.9
		c0.6-0.3,1-1,0.7-1.6c-0.3-0.7-1-0.9-1.6-0.6l0,0c-0.6,0.3-1,1-0.7,1.6C54.2,19.9,55,20.1,55.6,19.9L55.6,19.9z"
            />
            <path
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              d="M56.1,24.7L56,24l-1.2,0.2
		l-0.2-0.9l4-0.8l0.3,1.6c0.2,1-0.3,1.6-1.1,1.8l0,0C56.9,26.2,56.3,25.6,56.1,24.7 M57.6,25.1c0.4-0.1,0.5-0.4,0.5-0.8L58,23.6
		l-1.2,0.2l0.1,0.7C57,25,57.3,25.2,57.6,25.1L57.6,25.1z"
            />
            <path
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              d="M55.1,32.7
		c0.1-1.3,1.2-2,2.3-1.9l0,0c1.1,0.1,2,1.2,1.8,2.4c-0.2,1.3-1.2,2-2.3,1.9l0,0C55.8,35,55,34,55.1,32.7 M57,34.2
		c0.7,0.1,1.3-0.4,1.4-1.1c0.1-0.7-0.4-1.3-1.1-1.4l0,0c-0.7-0.1-1.3,0.4-1.4,1.1C55.8,33.5,56.3,34.1,57,34.2L57,34.2z"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="57.3,38.4 
		56.4,38.1 55.8,39.9 55.1,39.7 55.7,37.8 54.2,37.3 54.5,36.5 58.3,37.8 57.3,40.7 56.6,40.5 	"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="53.3,46.4 
		50.7,44.5 51.2,43.8 53.8,45.6 54.5,44.6 55.2,45.1 53.3,47.9 52.6,47.4 	"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="47,48.6 48.2,49.8 
		49.4,48.6 48.2,47.5 48.9,46.8 51.7,49.8 51,50.4 49.9,49.2 48.7,50.4 49.9,51.5 49.2,52.2 46.4,49.2 	"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="44.6,50.6 46.8,54 
		44.2,55.7 43.8,55 45.6,53.8 45.2,53.1 43.6,54.1 43.1,53.5 44.8,52.5 44.3,51.7 42.4,52.9 42,52.2 	"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="38,57.3 37.8,56.5 
		36,57 35.7,56.2 37.6,55.7 37.2,54.2 38.1,53.9 39.1,57.9 36.1,58.7 35.9,57.9 	"
            />
            <path
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              d="M31.8,55.1
		c1.2-0.1,2.2,0.8,2.3,2l0,0c0.1,1.2-0.8,2.2-2,2.3c-1.2,0.1-2.2-0.8-2.3-2l0,0C29.7,56.1,30.5,55.1,31.8,55.1 M30.7,57.2
		c0,0.7,0.6,1.2,1.3,1.2c0.7,0,1.2-0.7,1.1-1.4l0,0c0-0.7-0.6-1.2-1.3-1.2C31.1,55.9,30.7,56.5,30.7,57.2L30.7,57.2z"
            />
            <path
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              d="M26.1,54.8
		c1.2,0.2,2,1.3,1.8,2.4l0,0c-0.2,1.1-1.2,1.9-2.5,1.7c-1.2-0.2-2-1.3-1.8-2.4l0,0C23.8,55.4,24.8,54.6,26.1,54.8 M24.5,56.7
		c-0.1,0.7,0.3,1.4,1,1.5c0.7,0.1,1.3-0.4,1.4-1.1l0,0c0.1-0.7-0.3-1.4-1-1.5C25.2,55.5,24.6,56,24.5,56.7L24.5,56.7z"
            />
            <path
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              d="M20.8,53.4l1.5,0.6l-1.5,3.8
		l-1.5-0.6c-1.2-0.5-1.7-1.6-1.2-2.7l0,0C18.5,53.4,19.6,52.9,20.8,53.4 M18.9,54.8c-0.3,0.7,0,1.3,0.7,1.6l0.6,0.3l0.9-2.3
		l-0.6-0.3C19.8,53.9,19.2,54.1,18.9,54.8L18.9,54.8z"
            />
            <path
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              d="M13.8,49.1
		c0.9,0.8,1,2.1,0.3,3l0,0c-0.7,0.9-2,1.1-3,0.3c-0.6-0.5-0.8-1-0.8-1.5l0.9-0.1c0.1,0.4,0.2,0.8,0.5,1c0.5,0.4,1.3,0.3,1.7-0.2l0,0
		c0.4-0.5,0.4-1.3-0.1-1.7c-0.4-0.3-0.7-0.3-1.1-0.3l-0.1-0.8C12.7,48.5,13.2,48.6,13.8,49.1"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="9.7,44.8 8.4,45.8 
		9.5,47.1 10.7,46.1 11.3,46.8 8.1,49.3 7.6,48.6 8.8,47.6 7.8,46.3 6.5,47.3 6,46.6 9.2,44.1 	"
            />
            <path
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              d="M6.8,39.6l-0.7,0.7l0.7,1.6
		l1-0.1l0.4,0.8l-4.4,0.2l-0.4-0.7l2.9-3.4L6.8,39.6z M4.6,42L6,41.9l-0.5-1L4.6,42z"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="5.9,37.1 2,38.3 
		1.8,37.4 5.7,36.3 	"
            />
            <polygon
              clipPath="url(#SVGID_00000024683625024831304530000008689717097200276658_)"
              fill="currentColor"
              points="4.9,31.5 2.5,33.8 
		5.1,33.5 5.2,34.4 1.2,34.8 1.1,34 3.3,31.8 0.9,32.1 0.8,31.2 4.8,30.8 	"
            />
          </g>
        </svg>
      </div>
    </div>
  );
}
